import React, { useEffect, useState } from 'react'

const full = require('../assets/Full.png')
const half = require('../assets/Half 2.png')
const empty = require('../assets/Empty.png')

const Rating = (props) => {

    const { rating = "0", size = 18 } = props;
    const [stars, setStars] = useState([]);

    useEffect(() => {

        if (rating.includes(".")) {
            let rate = rating.slice(".");
            // alert(rate[2])
            if (rate[0]) {

                let star = ['empty', 'empty', 'empty', 'empty', 'empty'];

                for (let index = 0; index <= rate[0] -1; index++) {
                    star[index] = 'full';
                }
                if (rate[2] != 0) {
                    star[parseInt(rate[0])] = "half";
                }
                setStars(star);

            }

        }
        else {
            console.log(rating)
            let star = ['empty', 'empty', 'empty', 'empty', 'empty'];

            for (let index = 0; index < rating; index++) {
                star[index] = 'full';
            }
            setStars(star);
        }

    }, [rating])

    return (
        <div>
            {stars.map((e, i) => e == 'full' ? <img key={i} src={full} width={size} style={{ marginRight: 3 }} /> : e == "empty" ? <img key={i} src={empty} width={size} style={{ marginRight: 3 }} /> : <img key={i} src={half} width={size} style={{ marginRight: 3 }} />)}
        </div>
    )
}

export default Rating