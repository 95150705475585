import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SideBar from "./SideBar";
import './index.css'
import ProductVisual from './components/Product';
import GamePerformance from "./components/GamePerformance";
import Customization from "./components/Customisation";


const image1 = require('../assets/games/Group 41185.png')
const image2 = require('../assets/games/Group 41186.png')
const image3 = require('../assets/games/Group 41188.png')
const image4 = require('../assets/games/Group 41189.png')
const image5 = require('../assets/games/Group 41190.png')
const image6 = require('../assets/games/Group 41191.png')
const image8 = require('../assets/games/Group 41193.png')
const image9 = require('../assets/games/Group 41194.png')
const image10 = require('../assets/games/Group 41195.png')
const image11 = require('../assets/games/Group 41196.png')

const dataimport = require('../category-data.json')
const gamesdata = require('../game-data.json')

const Product = (props) => {


    const caseData = [
        { id: 1, name: 'case1', img: require('../assets/case.png'), rating: '0.5' },
        { id: 2, name: 'case2', img: require('../assets/case.png'), rating: '1.0' },
        { id: 3, name: 'case3', img: require('../assets/case.png'), rating: '1.5' },
        { id: 4, name: 'case4', img: require('../assets/case.png'), rating: '2.0' },
        { id: 5, name: 'case5', img: require('../assets/case.png'), rating: '2.5' },
        { id: 6, name: 'case6 motherboard acer asp', img: require('../assets/case.png'), rating: '3' },
        { id: 7, name: 'case6 ', img: require('../assets/case.png'), rating: '3.5' },
        { id: 8, name: 'case6 ', img: require('../assets/case.png'), rating: '4.0' },
        { id: 9, name: 'case6 ', img: require('../assets/case.png'), rating: '4.5' },
        { id: 10, name: 'case6 ', img: require('../assets/case.png'), rating: '5.0' }
    ]
    const Processor = [
        { id: 1, name: 'Processor1', img: require('../assets/processor.png') },
        { id: 2, name: 'processor2', img: require('../assets/processor.png') },
        { id: 3, name: 'processor3', img: require('../assets/processor.png') },
        { id: 4, name: 'processor4', img: require('../assets/processor.png') },
        { id: 5, name: 'processor5', img: require('../assets/processor.png') },
        { id: 6, name: 'processor6', img: require('../assets/processor.png') }
    ]
    const Motherboard = [
        { id: 1, name: 'Mother Board 1', img: require('../assets/motherboard.png') },
        { id: 2, name: 'Mother Board 2', img: require('../assets/motherboard.png') },
        { id: 3, name: 'Mother Board 3', img: require('../assets/motherboard.png') },
        { id: 4, name: 'Mother Board 4', img: require('../assets/motherboard.png') },
        { id: 5, name: 'Mother Board 5', img: require('../assets/motherboard.png') },
        { id: 6, name: 'Mother Board 6', img: require('../assets/motherboard.png') }
    ]
    const Memory = [
        { id: 1, name: 'memory1', img: require('../assets/RAM.png') },
        { id: 2, name: 'memory2', img: require('../assets/RAM.png') },
        { id: 3, name: 'memory3', img: require('../assets/RAM.png') },
        { id: 4, name: 'memory4', img: require('../assets/RAM.png') },
        { id: 5, name: 'memory5', img: require('../assets/RAM.png') },
        { id: 6, name: 'memory6', img: require('../assets/RAM.png') }
    ]
    const Graphic = [
        { id: 1, name: 'graphic1', img: require('../assets/GraphicCardd.png') },
        { id: 2, name: 'graphic2', img: require('../assets/GraphicCardd.png') },
        { id: 3, name: 'graphic3', img: require('../assets/GraphicCardd.png') },
        { id: 4, name: 'graphic4', img: require('../assets/GraphicCardd.png') },
        { id: 5, name: 'graphic5', img: require('../assets/GraphicCardd.png') },
        { id: 6, name: 'graphic6', img: require('../assets/GraphicCardd.png') }
    ]
    const Storage = [
        { id: 1, name: 'storage1', img: require('../assets/SSD.png') },
        { id: 2, name: 'storage2', img: require('../assets/SSD.png') },
        { id: 3, name: 'storage3', img: require('../assets/SSD.png') },
        { id: 4, name: 'storage4', img: require('../assets/SSD.png') },
        { id: 5, name: 'storage5', img: require('../assets/SSD.png') },
        { id: 6, name: 'storage6', img: require('../assets/SSD.png') }
    ]
    const CPU_Cooler = [
        { id: 1, name: 'CPU fan1', img: require('../assets/CPUfan.png') },
        { id: 2, name: 'CPU fan2', img: require('../assets/CPUfan.png') },
        { id: 3, name: 'CPU fan3', img: require('../assets/CPUfan.png') },
        { id: 4, name: 'CPU fan4', img: require('../assets/CPUfan.png') },
        { id: 5, name: 'CPU fan5', img: require('../assets/CPUfan.png') },
        { id: 6, name: 'CPU fan6', img: require('../assets/CPUfan.png') }
    ]
    const POWER_Supply = [
        { id: 1, name: 'Power Supply1', img: require('../assets/PowerSupply.png') },
        { id: 2, name: 'Power Supply2', img: require('../assets/PowerSupply.png') },
        { id: 3, name: 'Power Supply3', img: require('../assets/PowerSupply.png') },
        { id: 4, name: 'Power Supply4', img: require('../assets/PowerSupply.png') },
        { id: 5, name: 'Power Supply5', img: require('../assets/PowerSupply.png') },
        { id: 6, name: 'Power Supply6', img: require('../assets/PowerSupply.png') }
    ]

    const [percentage, setPercentage] = useState(0)
    const { selectedtab } = props
    const [data, setData] = useState([])
    const [step, setStep] = useState(1);
    const [components, setComponents] = useState({})
    const [selectedComponent, setSelectedComponent] = useState({})
    const [laststep, setlaststep] = useState(1)
    const [showGames, setShowGames] = useState(false)
    const [selectedImage, setSelecetedGame] = useState(image1)
    const [selectTab, setSelectedTab] = useState('A');
    const [fullimage , setFullImage] = useState(false)

    const [showGame, setShowGame] = useState(false);



    const [_data, set_Data] = useState({})
    const [games, set_Games] = useState({})

    useEffect(()=>{
        if(showGame){
            setFullImage(false)
        }else{
            setFullImage(true)

        }
    },[showGame])

    const onsubmit = () => {
        alert(JSON.stringify(components));
    }

    const setComp = (key, val) => {
        let comp = components;
        if (components[key]) {
            if (components[key] == val) {
            } else {
                setlaststep(step)
            }
        } else {
            setlaststep(step)
        }
        comp[key] = val;
        setComponents(comp);
    }

    useEffect(() => {
        set_Data(dataimport)
        set_Games(gamesdata)

        if (showGames) {
            setShowGames(false)
        }
        if (step == 1) {
            setData(caseData)
        } else if (step == 2) {
            setData(Processor)

        } else if (step == 3) {
            setData(Motherboard)

        } else if (step == 4) {
            setData(Graphic)

        } else if (step == 5) {
            setData(Memory)

        } else if (step == 6) {
            setData(Storage)

        } else if (step == 7) {
            setData(CPU_Cooler)

        } else if (step == 8) {
            setData(POWER_Supply)

        } else if (step == 9) {
            setData(Processor)

        } else if (step == 10) {
            setData(Processor)

        } else if (step == 11) {
            setData(Processor)

        } else if (step == 12) {
            setData(Processor)
        }
    }, [step])

    useEffect(() => {
        console.log(showGame)
    }, [showGame])

    return (
        <>
            <Header />
            <div className="main-container" >
                <div className="productpage" >
                    <SideBar setShowGame={setShowGame} data={_data} percentage={percentage} setPercentage={setPercentage} step={step} setStep={setStep} setlaststep={setlaststep} laststep={laststep} setSelectedTab={setSelectedTab} selectTab={selectTab} />
                    <div className="productMain">
                        <div className="Product-visual" >
                            <ProductVisual fullimage={fullimage} selectedComponent={selectedComponent} step={step} />
                            {showGame && <div className="rounded px-4 game-performance-container" style={{ boxShadow: "-2px 0px 10px 0px rgba(0, 0, 0 , 0.3)", position: 'relative' }}>
                                {showGames &&
                                    <div style={{ position: 'absolute', width: "100%", height: '380px', boxShadow: "0px -5px 10px 0px rgba(0 , 0 , 0 , 0.3)", color: 'white', bottom: 160, left: 0, backgroundColor: '#141627' }}>
                                        <div className="games-box" style={{ margin: 20, display: 'grid', boxSizing: 'border-box', gridTemplateColumns: '1fr 1fr 1fr', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.8)', borderRadius: 10, overflow: 'scroll', height: '90%', }}>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image1); setShowGames(!showGames) }} ><img src={image1} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image2); setShowGames(!showGames) }} ><img src={image2} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image3); setShowGames(!showGames) }} ><img src={image3} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image4); setShowGames(!showGames) }} ><img src={image4} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image5); setShowGames(!showGames) }} ><img src={image5} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image6); setShowGames(!showGames) }} ><img src={image6} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image8); setShowGames(!showGames) }} ><img src={image8} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image9); setShowGames(!showGames) }} ><img src={image9} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                            <div className="p-2 rounded" style={{ cursor: 'pointer' }} onClick={() => { setSelecetedGame(image10); setShowGames(!showGames) }} ><img src={image10} style={{ borderRadius: 10 }} width={'100%'} height={'100%'} /> </div>
                                        </div>
                                    </div>}
                                <GamePerformance setShowGames={setShowGames} showGames={showGames} selectedGame={selectedImage} data={gamesdata} />
                            </div>}
                        </div>
                        <div className="customize-main" ><Customization setPercentage={setPercentage} percentage={percentage} setStep={setStep} step={step} onSubmit={onsubmit} setComp={setComp} data={data} setSelectedComponent={setSelectedComponent} components={components} setSelectedTab={setSelectedTab} selectTab={selectTab} /></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Product