import React, { useState } from "react";

const Dropdown = () => {

    const options = ['Assending', 'Decending']
    const [show, setShow] = useState(false);

    return (
        <div className="customization-header">
            <div style={{ borderRight: '1px solid white', margin: '5px 8px', paddingRight: '7px', backgroundImage: '' }} >Sort by</div>
            <div onClick={() => setShow(!show)} style={{ paddingRight: '7px', cursor: 'pointer' }}>Price Assending <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg></div>
            {show && <div style={{ position: 'absolute', right: 0, top: 31, backgroundColor: "rebeccapurple", zIndex: 10, borderRadius: '5px', cursor: 'pointer', padding: '5px' }}>
                {options.map((e) => {
                    return <div onClick={()=>alert('pending')} style={{ padding: '5px' }}>{e}</div>
                })}
            </div>}
        </div >
    )
}


export default Dropdown