import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Item from "./Item";
import Dropdown from "../../components/Dropdown";
import Filter from "../../components/Filter";
import ListingStyle from "../../components/ListingStyle";
import Rating from "../../components/Rating";

const Customization = (props) => {

    const { setPercentage, percentage, selectedtab, setStep, step, onSubmit, data = [], setComp, setSelectedComponent, components, selectTab } = props;

    const [product, setProduct] = useState("");
    const [sort, setSort] = useState('A')
    const [active, setActive] = useState(0)
    const [details, setDetails] = useState(null)
    const [listingStyle, setListingStyle] = useState("G")
    const [warning, setWarning] = useState(false)

    const onSelect = (e, i) => {
        setSelectedComponent(e);
        setProduct(e);
        // setComp(step, e.name)
        setActive(i)
    }

    const setPer = () => {
        let pos = selectTab == 'B' ? 8 : selectTab == "A" ? 1 : 2
        let steps = selectTab == 'B' ? 2 : selectTab == "A" ? 0 : selectTab == 'C' ? 8 : 10
        let previosPer = selectTab == 'B' ? 25 : selectTab == "A" ? 0 : selectTab == "C" ? 63 : 88
        let val = Math.ceil(((step - steps) * 100 / pos)) + previosPer
        let r = document.querySelector(':root');
        r.style.setProperty('--per', val + "%")
    }

    const adjustPercentage = () => {
        setPer()
        if (step == 2) {
            setPercentage(28)
        } else if (step == 3) {
            setPercentage(32)
        } else if (step == 4) {
            setPercentage(35)
        } else if (step == 5) {
            setPercentage(39)
        } else if (step == 6) {
            setPercentage(43)
        } else if (step == 7) {
            setPercentage(47)
        } else if (step == 8) {
            setPercentage(50)
        } else if (step == 9) {
            setPercentage(63)
        } else if (step == 10) {
            setPercentage(76)
        } else if (step == 11) {
            setPercentage(88)
        } else if (step == 12) {
            onSubmit()
        }
    }

    useEffect(() => {
        if (data.length) {
            setProduct(data[0])
        }
    }, [data])

    const next = () => {
        alert('pending')
        // adjustPercentage();
        // setComp(step, product)
        // setProduct("")
        // if (step == 12) {

        // } else {
        //     setStep(parseInt(step) + 1);
        // }
    }

    useEffect(() => {

        if (step != 12) {

            adjustPercentage();
        }

        if (data.length) {
            if (step == 1) {
                if (components['1'] && components['1'] != "") {
                    setProduct(components['1'])
                    setActive(components['1'].id)
                } else {
                    // alert(JSON.stringify(data));
                    setProduct(data[0])
                    setActive(1)
                }
            }
            else if (step == 2) {
                if (components['2'] && components['2'] != "") {
                    // alert(components['1'])

                    setProduct(components['2'])
                    setActive(components['2'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 3) {
                if (components['3'] && components['3'] != "") {
                    setProduct(components['3'])
                    setActive(components['3'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 4) {
                if (components['4'] && components['4'] != "") {
                    setProduct(components['4'])
                    setActive(components['4'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 5) {
                if (components['5'] && components['5'] != "") {
                    setProduct(components['5'])
                    setActive(components['5'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 6) {
                if (components['6'] && components['6'] != "") {
                    setProduct(components['6'])
                    setActive(components['6'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 7) {
                if (components['7'] && components['7'] != "") {
                    setProduct(components['7'])
                    setActive(components['7'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 8) {
                if (components['8'] && components['8'] != "") {
                    setProduct(components['8'])
                    setActive(components['8'].id)

                } else {
                    setProduct(data[0].name)
                    setActive(1)

                }
            }
            else if (step == 9) {
                if (components['9'] && components['9'] != "") {
                    setProduct(components['9'])
                    setActive(components['9'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 10) {
                if (components['10'] && components['10'] != "") {
                    setProduct(components['10'])
                    setActive(components['10'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 11) {
                if (components['11'] && components['11'] != "") {
                    setProduct(components['11'])
                    setActive(components['11'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
            else if (step == 12) {
                if (components['12'] && components['12'] != "") {
                    setProduct(components['12'])
                    setActive(components['12'].id)

                } else {
                    setProduct(data[0])
                    setActive(1)

                }
            }
        }
    }, [data, step])

    return (
        <div className=" custimization-container "  >
            <div className="custimizationsection">
                {details ?
                    <div style={{ overflow: 'scroll', display: 'flex', flexDirection: 'column', color: 'white', justifyContent: 'space-between', height: '88%', position: 'relative' }}>
                        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0px 15px' }}>
                            <div> {warning ? <img src={require('../../assets/warning-icon.png')} width={20} /> : ""} </div>
                            <img src={require('../../assets/Cross.png')} onClick={() => setDetails(null)} width={20} height={20} style={{ borderRadius: 5, backgroundColor: '#42387d', padding: 6, cursor: 'pointer' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10 }} >
                            <div className="text-center w-50">
                                <div className="customization-details-title mb-2" >{details.name}</div>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 'auto', width: 'fit-content', marginBottom: 10 }}>
                                        <div style={{ fontSize: 10, marginRight: 25 }}>

                                            <div className='customization-details-heading' >Time</div>
                                            <div className='customization-details-text' >+ 10 days</div>
                                        </div>
                                        <div style={{ fontSize: 10 }}>
                                            <div className='customization-details-heading' >Price</div>
                                            <div className='customization-details-text' >1900.00</div>    </div>
                                    </div>
                                    <div>
                                        <div className='customization-details-heading mb-0 mt=3' >Our Rating</div>
                                        <Rating rating={details?.rating} />
                                    </div>
                                </div>
                            </div>
                            <div className="w-50" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} ><img src={details.img} height={130} /></div>
                        </div>
                        <div style={{ fontSize: 12, textAlign: 'center', margin: 4 }} >
                            ASUS Dual GeForce RTX™ 4060 Ti OC fuses dynamic thermal performance with broad compatibility. Advanced cooling solutions from flagship graphics cards — including two Axial-tech fans for maximizing airflow to the heatsink — are packed into the 22.7 cm long, 2.5-slot card, delivering more power in less space. These enhancements make ASUS Dual the perfect choice for gamers who want heavyweight graphics performance in a compact build. Take control of the lobby and be the best you can be with ASUS 40-series graphics.
                        </div>
                        <div className="customize-features p-3" >
                            features
                            <div className="m-1 p-1 rounded" style={{ fontSize: 10 }} > NVIDIA Ada Lovelace Streaming Multiprocessors: Up to 2x performance and power efficiency</div>
                            <div className="m-1 p-1 rounded" style={{ fontSize: 10 }} > NVIDIA Ada Lovelace Streaming Multiprocessors: Up to 2x performance and power efficiency</div>
                            <div className="m-1 p-1 rounded" style={{ fontSize: 10 }} > NVIDIA Ada Lovelace Streaming Multiprocessors: Up to 2x performance and power efficiency</div>
                            <div className="m-1 p-1 rounded" style={{ fontSize: 10 }} > NVIDIA Ada Lovelace Streaming Multiprocessors: Up to 2x performance and power efficiency</div>
                            <div className="m-1 p-1 rounded" style={{ fontSize: 10 }} > NVIDIA Ada Lovelace Streaming Multiprocessors: Up to 2x performance and power efficiency</div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="title text-light">CUSTOMIZE YOUR PC</div>
                        <div className="customheader">
                            <div className="left" >
                                <div style={{ margin: '0px 5px 5px 5px' }}>
                                    <Dropdown />
                                </div>
                                <div style={{ margin: '0px 5px 5px 5px' }}>
                                    <Filter />
                                </div>
                            </div>
                            <div className="right">
                                <ListingStyle ListingStyle={listingStyle} setListingStyle={setListingStyle} />
                            </div>
                        </div>
                        <div className={`${listingStyle == "G" ? 'custimizationsection-item' : 'custimizationsection-list-item'}`} >
                            {data.map((e, i) => <Item index={i} click={onSelect} active={active} data={e} listing={listingStyle} setDetails={setDetails} />)}
                        </div>
                    </>
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0px', alignItems: 'center' }}>
                    <div> <span className="customization-selected-gradient">Selected: </span><span className="fs-6 text-light"> {product.name} </span></div><div onClick={next} className="btn-primary" style={{}}>Next step</div>
                </div>
            </div>

        </div>
    )
}

export default Customization    