import React from "react";
import "./footer.css"
const Footer = () => {

    return (
        <div
            style={{ height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderTop: '1px solid rgba(48, 193, 212, 1)', margin: '0px 20px' }}
        >
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '50px' }}>
                <div className="btn-primary">System Summery</div>
                <div style={{ display: 'flex', marginLeft: '20px', alignItems: 'center' }}>
                    <div style={{
                        marginRight: "10px",
                        background: 'rgba(57, 62, 99, 0.5)',

                        padding: "2px 9px 8px 9px",
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" color="white" fill="currentColor" class="bi bi-floppy" viewBox="0 0 16 16">
                            <path d="M11 2H9v3h2V2Z" />
                            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0ZM1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5Zm3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4v4.5ZM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V15Z" />
                        </svg></div>
                    <div style={{ background: 'rgba(57, 62, 99, 0.5)', padding: "2px 9px 8px 9px", borderRadius: '4px', cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" color="white" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                    </svg></div>
                </div>
            </div>
            <div style={{ display: 'flex', color: 'white', marginRight: '50px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <div style={{ lineHeight: "12px", borderRight: '1px solid lightgray', paddingRight: '10px', marginRight: '10px' }} >
                        <span style={{ fontSize: '12px' }} >Expected</span><br /><span style={{ fontSize: '12px' }}  >Ship Date</span>
                    </div>
                    <div className="footer-gradient-text">23/12/23</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <div style={{ lineHeight: "12px", borderRight: '1px solid lightgray', paddingRight: '10px', marginRight: '10px' }} >
                        <span style={{ fontSize: '12px' }} >Subtotal</span><br /><span style={{ fontSize: '12px' }}  >Inc. VAT</span>
                    </div>
                    <div className="footer-gradient-text" >1998.78</div>
                </div>
            </div>
        </div >
    )
}

export default Footer