import React, { useState, useEffect } from 'react'

const SideBar = (props) => {

    const { step, percentage, setPercentage, setStep, setlaststep, laststep, selectTab, setSelectedTab, data, setShowGame } = props;

    const [progress, setProgress] = useState(0);
    const [curvestyle, setcurveStyle] = useState({ height: 60, top: -60 })
    const [activeTab, setActiveTab] = useState(1)
    const [activeSubTab, setActiveSubTab] = useState(1)
    const [percent, setPercent] = useState('0')

    const changeTab = (tab, progress) => {
        if (tab == 'A') {
            setStep(1)
        }
        if (tab == 'B') {
            if (laststep > 1)
                setStep(2)
        }
        if (tab == 'C') {
            if (laststep > 8)
                setStep(9)
        }
        if (tab == 'D') {
            if (laststep > 10)
                setStep(11)
        }

        if (step == 1) {

        }
        else if (step <= 8) {
            if (selectTab == "C" || selectTab == "D") {

            } else if (tab == "A" || tab == "B") {
                setProgress(progress);
                setSelectedTab(tab);
            }
        } else if (step <= 10) {
            if (selectTab == "D") {
            } else if (tab == "A" || tab == "B" || tab == "C") {
                setProgress(progress);
                setSelectedTab(tab);
            }

        } else if (step <= 11) {
            setProgress(progress);
            setSelectedTab(tab);
        }
    }

    const gotostep = (goto) => {
        if (laststep > goto) {
            setStep(goto)
        }
    }

    const setActiveTabs = (val) => {
        setShowGame(val > 1)
        setActiveSubTab(val == 1 ? "11" : val == 2 ? "21" : val == 3 ? "31" : "41")
        setPercent(val == 1 ? "0" : val == 2 ? "25" : val == 3 ? "50" : "75")
    }

    useEffect(() => {

        let cursty = {
            height: step == 1 ? 60 : step == 2 ? 120 : step == 3 ? 140 : step == 4 ? 180 : step == 5 ? 220 : step == 6 ? 240 : step == 7 ? 280 : step == 8 ? 330 : step == 9 ? 230 : step == 10 ? 250 : step == 11 ? 280 : 320,
            top: step == 1 ? -60 : step == 2 ? -120 : step == 3 ? -140 : step == 4 ? -180 : step == 5 ? -220 : step == 6 ? -240 : step == 7 ? -280 : step == 8 ? -330 : step == 9 ? -230 : step == 10 ? -250 : step == 11 ? -280 : -320
        }

        setcurveStyle(cursty);
        if (step > laststep) {
            setlaststep(step);
        }
        if (step == 1) {
            setSelectedTab('A')
            setProgress(100)
        }
        if (step >= 2 && step <= 8) {
            setSelectedTab('B')
            setProgress(130)
        }
        if (step > 8 && step <= 10) {
            setSelectedTab('C')
            setProgress(200)
        }
        if (step >= 11) {
            setSelectedTab("D")
            setProgress(300)
        }

    }, [step])

    return (
        <div className='sidebar-container' >
            <div className='sidebar-data' style={{ height: step > 10 ? 349 : 'auto' }} >
                <div className='sidebar-tabs noTopPadding'>
                    {data['categories'] && Object.entries(data['categories']['list']).map(([k, v], icate) => {
                        return (
                            <div style={{  margin: '10px 0' }} >
                                <div><div className={`sidbar-title ${[k] <= activeTab ? 'percentage' : "percentage-lock"} `} ><div>{[k] == activeTab ? <div style={{ fontSize: '16px' }} >{Math.ceil(parseInt(percent)) + '%'}</div> : activeTab > [k] ? <div style={{ fontSize: '12px' }} >Done</div> : <img src={require('../assets/Padlock.png')} width={20} height={25} />}</div></div></div>
                                <div onClick={() => { setActiveTab(k); setActiveTabs(k) }} className={`sidbar-title ${[k] == activeTab ? 'active-title' : ""} `} >
                                    {v.name}
                                </div>
                                {v.subcats && ([k] == activeTab) && Object.entries(v.subcats).map(([k, v2], i) => {
                                    return (
                                        <div className={` tabs  ${[k] == activeSubTab ? "active" : ' '}`} style={{ position: 'relative' }} onClick={() => { setActiveSubTab(k); setPercent((prev) => (((((i + 1) * 25)) / Object.keys(v.subcats).length) + ((icate ? 25 : 0) * (icate)))) }}>
                                            {v2.name}
                                            <div className={` tabs ${'progress-curve-line-thin '}`}>
                                                <div className={` tabs ${[k] == activeSubTab ? '' : 'progress-curve-thin '}`} style={curvestyle} ></div>
                                                <div className={` tabs ${[k] == activeSubTab ? 'tab-logo-active' : 'tab-logo'} `} ><img src={require('../assets/Icon.png')} height={[k] == activeSubTab ? 20 : 15} /></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                </div>
            </div>

            {/* <div className='sidebar-data' style={{ height: step > 10 ? 349 : 'auto' }} >
                <div className='sidebar-tabs noTopPadding'>
                    <div>{step >= 2 ? <div className='percentage-done' > Done</div> : <div className='percentage progress-bar-custom' > <div style={{rotate:"-180deg"}} >0%</div></div>}</div>
                    <div>
                        <div onClick={() => changeTab("A", 245)} className={`sidbar-title ${selectTab == "A" ? " active-title" : " "} ${laststep >= 2 ? "sidebar-title-done" : ' '}`} >
                            AESTHESTICS
                        </div>
                        {selectTab == "A" &&
                            <div className={` tabs ${step == 1 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('1')}>
                                case
                                <div className={` tabs ${step == 1 ? "progress-curve-line" : 'progress-curve-line-thin '}`}>
                                    <div className={` tabs ${step == 1 ? "progress-design-curve" : 'progress-curve-thin '}`} style={curvestyle} ></div>
                                    <div className={` tabs ${step == 1 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 1 ? 20 : 15} /></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='sidebar-tabs'>
                    <div>{laststep > 8 ? <div className='percentage-done' > Done</div> : step < 2 ? <div className='percentage-lock' > <img src={require('../assets/Padlock.png')} width={20} height={25} /></div> : <div className='percentage progress-bar-custom' > <div style={{rotate:"-180deg"}} >{Math.ceil(percentage)}%</div> </div>}</div>
                    <div>
                        <div onClick={() => changeTab("B", 300)} className={`sidbar-title ${selectTab == "B" ? " active-title" : " "} ${laststep > 8 ? "sidebar-title-done" : ' '}`} >
                            CORE
                        </div>
                        {selectTab == "B" &&
                            <div>
                                <div className={` tabs ${step == 2 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('2')} >
                                    Processor
                                    <div className={` tabs ${step == 2 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 2 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 2 ? curvestyle : { height: 40, top: -40 }} ></div>
                                        <div className={` tabs ${step == 2 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 2 ? 20 : 15} /></div>
                                    </div>
                                </div>
                                <div className={` tabs ${step == 3 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('3')} >
                                    Motherboard
                                    <div className={` tabs ${step == 3 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 3 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 3 ? curvestyle : { height: 40, top: -40 }}></div>
                                        <div className={` tabs ${step == 3 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 3 ? 20 : 15} /></div>

                                    </div>
                                </div>
                                <div className={` tabs ${step == 4 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('4')}>
                                    Graphic card
                                    <div className={` tabs ${step == 4 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 4 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 4 ? curvestyle : { height: 40, top: -40 }} ></div>
                                        <div className={` tabs ${step == 4 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 4 ? 20 : 15} /></div>

                                    </div>
                                </div>
                                <div className={` tabs ${step == 5 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('5')}  >
                                    Memory
                                    <div className={` tabs ${step == 5 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 5 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 5 ? curvestyle : { height: 40, top: -40 }}></div>
                                        <div className={` tabs ${step == 5 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 5 ? 20 : 15} /></div>

                                    </div>
                                </div>
                                <div className={` tabs ${step == 6 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('6')} >
                                    Storage
                                    <div className={` tabs ${step == 6 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 6 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 6 ? curvestyle : { height: 30, top: -30 }} ></div>
                                        <div className={` tabs ${step == 6 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 6 ? 20 : 15} /></div>

                                    </div>
                                </div>
                                <div className={` tabs ${step == 7 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('7')} >
                                    CPU Fan
                                    <div className={` tabs ${step == 7 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 7 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 7 ? curvestyle : { height: 40, top: -40 }}></div>
                                        <div className={` tabs ${step == 7 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 7 ? 20 : 15} /></div>

                                    </div>
                                </div>
                                <div className={` tabs ${step == 8 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('8')} >
                                    Power supply
                                    <div className={` tabs ${step == 8 ? "progress-curve-line" : 'progress-curve-line-thin '}`}  >
                                        <div className={` tabs ${step == 8 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 8 ? curvestyle : { height: 40, top: -40 }}></div>
                                        <div className={` tabs ${step == 8 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 8 ? 20 : 15} /></div>

                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div className='sidebar-tabs'>
                    <div>
                        <div>{laststep > 10 ? <div className='percentage-done' > Done</div> : step < 9 ? <div className='percentage-lock' > <img src={require('../assets/Padlock.png')} width={20} height={25} /></div> : <div className='percentage progress-bar-custom' ><div style={{rotate:"-180deg"}} >{Math.ceil(percentage)}%</div></div>}</div>
                        <div onClick={() => changeTab("C", 330)} className={`sidbar-title ${selectTab == "C" ? " active-title" : " "} ${laststep > 10 ? "sidebar-title-done" : ' '}`} >
                            EXTRAS
                        </div>
                        {selectTab == "C" &&
                            <div>
                                <div className={` tabs ${step == 9 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('9')}  >
                                    data
                                    <div className={` tabs ${step == 9 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 9 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 9 ? curvestyle : { height: 40, top: -40 }}></div>
                                        <div className={` tabs ${step == 9 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 9 ? 20 : 15} /></div>

                                    </div>
                                </div>
                                <div className={` tabs ${step == 10 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('10')} >
                                    data
                                    <div className={` tabs ${step == 10 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                        <div className={` tabs ${step == 10 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 10 ? curvestyle : { height: 40, top: -40 }}></div>
                                        <div className={` tabs ${step == 10 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 10 ? 20 : 15} /></div>

                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div className='sidebar-tabs noBottomPadding'>
                    <div>{laststep >= 12 ? <div className='percentage-done' > Done</div> : step < 11 ? <div className='percentage-lock' ><img src={require('../assets/Padlock.png')} width={20} height={25} /> </div> : <div className='percentage progress-bar-custom' ><div style={{rotate:"-180deg"}} >{Math.ceil(percentage)}%</div></div>}</div>

                    <div onClick={() => changeTab("D", 340)} className={`sidbar-title ${selectTab == "D" ? " active-title" : " "} ${laststep > 12 ? "sidebar-title-done" : ' '}`}  >
                        ACCESSORIES
                    </div>
                    {selectTab == "D" &&
                        <div>
                            <div className={` tabs ${step == 11 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('11')} >
                                data
                                <div className={` tabs ${step == 11 ? "progress-curve-line" : 'progress-curve-line-thin '}`} >
                                    <div className={` tabs ${step == 11 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 11 ? curvestyle : { height: 40, top: -40 }}></div>
                                    <div className={` tabs ${step == 11 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 11 ? 20 : 15} /></div>

                                </div>
                            </div>
                            <div className={` tabs ${step == 12 ? " active " : ' '}`} style={{ position: 'relative' }} onClick={() => gotostep('12')}>
                                data
                                <div className={` tabs ${step == 12 ? "progress-curve-line" : 'progress-curve-line-thin '}`}  >
                                    <div className={` tabs ${step == 12 ? "progress-design-curve" : 'progress-curve-thin '}`} style={step == 12 ? curvestyle : { height: 40, top: -40 }}></div>
                                    <div className={` tabs ${step == 12 ? 'tab-logo-active' : 'tab-logo'}`} ><img src={require('../assets/Icon.png')} height={step == 12 ? 20 : 15} /></div>

                                </div>
                            </div>
                        </div>}
                </div>
            </div> */}
        </div>
    )
}

export default SideBar
