import React from "react";
import Progress from "./StepProgress";

const GamePerformance = (props) => {

    const { resulution = 2, graphic = 1, frames = 190, setShowGames, showGames, selectedGame  } = props

    const calculateFrams = (frames = 100) => {
        let maxFrames = 200
        return frames * 100 / maxFrames
    }

    return (
        <div className="game-performance">
            <div style={{ height: '100%', width: '160px', padding: '20px 0px' }}>
                <img onClick={() => setShowGames(!showGames)} src={selectedGame} height={'80%'} width={'100%'} alt={'gameimage'} style={{ objectFit: 'contain', borderRadius: '10px' }} />
            </div>
            <div style={{ width: '170px' }} >
                <div>
                    <div className="text-center fw-bold text-white" style={{ paddingTop: '3px', fontSize: 12 }} >GRAPHICS SETTINGS</div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }} >
                        <Progress captions={['Low', 'Med', 'High', 'Ultra',]}  />
                    </div>
                    <div className="text-center fw-bold text-white" style={{ paddingTop: '3px', fontSize: 12 }} >RESOLUTION</div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }} >
                        <Progress captions={['720p', '1080p', '1440p', '2k',]} />
                    </div>
                </div>
                <div>
                    <div></div>
                </div>
            </div>
            <div className="text-center fs-6" style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                {/* <div>FPS Range</div> */}
                {/* <div style={{ width: '160px', borderRadius: 6, backgroundColor: 'rgb(157, 0, 181)', position: 'relative', height: '20px' }} >
                    <div style={{ fontSize: '14px', borderRadius: 6, padding: '0px 5px', color: 'white', lineHeight: "26px", backgroundColor: '#2588c2', width: 'fit-content', position: 'absolute', height: '26px', bottom: '-3px', left: calculateFrams(frames) }}>{(parseInt(frames)) - 10 + "-" + (parseInt(frames) + 10)}</div>
                </div> */}
                <div className="progress-bar-custom-per mt-2" style={{ position: 'relative' }}>
                    <div className="progress-bar-custom-per-inner" style={{ position: 'absolute', top: -3, left: -3 }} ></div>
                </div>
                <div className="text-light" style={{ position: 'absolute', bottom: 58, fontSize: 18, fontWeight: 700 }}>120-140</div>
                <div className="text-light" style={{ position: 'absolute', bottom: 22 }}>Average FPS Range</div>
                <div className="text-light" style={{ position: 'absolute', bottom: 5, fontSize: 8, display: 'flex', alignItems: 'center' }}> <img src={require('../../assets/warning-white.png')} width={10} />Your game performance may very</div>
            </div>


        </div>
    )
}

export default GamePerformance