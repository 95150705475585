import logo from './logo.svg';
import './App.css';
import Product from './ProductPage/Index';
function App() {
  return (
    <Product/>
  );
}

export default App;
