import React from 'react';
import './Header.css'

const Header = () => {

    return (
        <div className='header'>
            <div className='headerLeft'> <img src={require('../assets/vibox.png')}  style={{cursor:'pointer'}} /></div>
            <div className='headerCenter'>
                <div className='headerCenterContent'>
                    <div>Gaming PC</div>
                    <div>Custom PC Builder</div>
                    <div>Sort By Games</div>
                    <div>Gaming Laptops</div>
                    <div>Support</div>
                </div>
            </div>
            <div className='headerRight'>
                <img src={require('../assets/cart.png')} width={15} />
                <img src={require('../assets/profile.png')} width={15}/>
            </div>
        </div>
    )
}

export default Header