import React, { useEffect, useState } from 'react'

const Progress = (props) => {

    const [active, setActive] = useState(2)
    const [progress, setProgress] = useState('33')

    const { captions = ['1', '2', '3', '4'] } = props

    const setActiveDot = (val) => {
        if (val <= 1) {
            setActive(1)
        }
        if (val > 28) {
            setActive(2)
        }
        if (val > 60) {
            setActive(3)
        }
        if (val > 90) {
            setActive(4)
        }
    }

    const animate = (val) => {
        let prev = parseInt(progress);

        if (val > progress) {

            let interval = setInterval(() => {
                setActiveDot(prev)
                if (prev >= val) {
                    clearInterval(interval);
                } else {
                    setProgress((prev) => JSON.stringify(parseInt(prev) + 2));
                    prev = prev + 2;
                }
            }, 2)
        } else {
            let interval = setInterval(() => {
                setActiveDot(prev)

                if (prev <= val) {
                    clearInterval(interval);
                } else {
                    setProgress((prev) => JSON.stringify(parseInt(prev) - 2));
                    prev = prev - 2;
                }
            }, 2)
        }
    }

    // useEffect(() => {
    //     if (active == 1) {
    //         animate('1')
    //     }
    //     if (active == 2) {
    //         animate('33')
    //     }
    //     if (active == 3) {
    //         // setProgress("66")
    //         animate('66')
    //     }
    //     if (active == 4) {
    //         animate('99')
    //     }
    // }, [active])


    return (
        <div style={{ position: 'relative', width: '100%' }} >
            <div style={{ width: '100%', height: 2, backgroundColor: '#1e1d3c', position: 'absolute', bottom: 7 }} >
                <div style={{ backgroundColor: '#23bdcf', width: progress + '%', height: 2 }} ></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'relative' }}>
                <div onClick={() => animate('0')} style={{ width: active == 1 ? 17 : 14, height: active == 1 ? 17 : 14, backgroundColor: active >= 1 ? '#23bdcf' : '#1e1d3c', borderRadius: "50%", boxShadow: 'inset -1px 0px 6px 1px rgba(0,0,0,0.6)' , cursor:'pointer' }} >

                    <div style={{ marginTop: 16, color: 'white', fontSize: 10, color: active == 1 ? 'white' : '#35375a' }} >{captions[0]}</div>
                </div>
                <div onClick={() => animate('33')} style={{ width: active == 2 ? 17 : 14, height: active == 2 ? 17 : 14, backgroundColor: active >= 2 ? '#23bdcf' : '#1e1d3c', borderRadius: "50%", boxShadow: 'inset -1px 0px 6px 1px rgba(0,0,0,0.6)' , cursor:'pointer' }} >
                    <div style={{ marginTop: 16, color: 'white', fontSize: 10, color: active == 2 ? 'white' : '#35375a' }} >{captions[1]}</div>

                </div>
                <div onClick={() => animate('66')} style={{ width: active == 3 ? 17 : 14, height: active == 3 ? 17 : 14, backgroundColor: active >= 3 ? '#23bdcf' : '#1e1d3c', borderRadius: "50%", boxShadow: 'inset -1px 0px 6px 1px rgba(0,0,0,0.6)' , cursor:'pointer' }} >
                    <div style={{ marginTop: 16, color: 'white', fontSize: 10, color: active == 3 ? 'white' : '#35375a' }} >{captions[2]}</div>

                </div>
                <div onClick={() => animate('99')} style={{ width: active == 4 ? 17 : 14, height: active == 4 ? 17 : 14, backgroundColor: active >= 4 ? '#23bdcf' : '#1e1d3c', borderRadius: "50%", boxShadow: 'inset -1px 0px 6px 1px rgba(0,0,0,0.6)' , cursor:'pointer' }} >
                    <div style={{ marginTop: 16, color: 'white', fontSize: 10, color: active == 4 ? 'white' : '#35375a' }} >{captions[3]}</div>

                </div>
            </div>
        </div>
    )

}

export default Progress