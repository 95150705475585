import React from "react";

const caseimg = require('../../assets/case.png')

const ProductVisual = (props) => {

    const { selectedComponent = {}, step, fullimage } = props

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignItems: "stretch" }} >
            <div className={`product-visual-image${fullimage ? "-full" : ""}`} >
                {/* <div>{JSON.stringify(selectedComponent)}</div> */}
                <img src={selectedComponent?.img ? selectedComponent.img : caseimg} alt={selectedComponent.name} />
            </div>
        </div>
    )
}

export default ProductVisual