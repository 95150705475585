import React, { useState } from "react";

const Filter = () => {

    const options = ['Assending', 'Decending']
    const [show, setShow] = useState(false)
    const [active, setActive] = useState('')
    // const [showFilters , setShowFilter] = useState(false)

    return (
        <div className="customization-header" style={{ position: 'relative' }} >
            { show && <div style={{ position: 'absolute', top: 35, borderRadius: 10, padding: 10, right: 0, backgroundColor: '#29254c', zIndex: 15, width: '260px' }} >
                <div style={{ width: '100%' }} >
                    <div style={{ borderBottom: '1px solid darkgray' }}>
                        <div onClick={() => { active == "A" ? setActive("") : setActive("A") }} className="filter-tabs">
                            <div className="filter-tabs-heading" >Manufacturer</div>
                            {active == "A" ? <img src={require('../assets/iconup.png')} width={10} /> : <img src={require('../assets/icondown.png')} width={10} />}
                        </div>
                        {active == "A" && <div className="filter-tabs-data">
                            Test
                        </div>}
                    </div>
                    <div style={{ borderBottom: '1px solid darkgray' }}>
                        <div onClick={() => { active == "B" ? setActive("") : setActive("B") }} className="filter-tabs">
                            <div className="filter-tabs-heading" >Modal</div>
                            {active == "B" ? <img src={require('../assets/iconup.png')} width={10} /> : <img src={require('../assets/icondown.png')}width={10} />}

                        </div>
                        {active == "B" && <div className="filter-tabs-data">
                            Test
                        </div>}
                    </div>
                    <div style={{ borderBottom: '1px solid darkgray' }}>
                        <div onClick={() => { active == "C" ? setActive("") : setActive("C") }} className="filter-tabs">
                            <div className="filter-tabs-heading" >Brand</div>
                            {active == "C" ? <img src={require('../assets/iconup.png')} width={10} /> : <img src={require('../assets/icondown.png')}width={10} />}

                        </div>
                        {active == "C" && <div className="filter-tabs-data">
                            Test
                        </div>}
                    </div>
                    <div style={{ borderBottom: '1px solid darkgray' }}>
                        <div onClick={() => { active == "D" ? setActive("") : setActive("D") }} className="filter-tabs">
                            <div className="filter-tabs-heading" >VRAM</div>
                            {active == "D" ? <img src={require('../assets/iconup.png')} width={10} /> : <img src={require('../assets/icondown.png')} width={10}/>}

                        </div>
                        {active == "D" && <div className="filter-tabs-data">
                            Test
                        </div>}
                    </div>
                    <div style={{ borderBottom: '1px solid darkgray' }}>
                        <div onClick={() => { active == "E" ? setActive("") : setActive("E") }} className="filter-tabs">
                            <div className="filter-tabs-heading" >GPU Length</div>
                            {active == "E" ? <img src={require('../assets/iconup.png')} width={10} /> : <img src={require('../assets/icondown.png')} width={10}/>}

                        </div>
                        {active == "E" && <div className="filter-tabs-data">
                            Test
                        </div>}
                    </div>
                    <div>
                        <div onClick={() => { active == "F" ? setActive("") : setActive("F") }} className="filter-tabs" style={{ border: 'none' }}>
                            <div className="filter-tabs-heading" >Features</div>
                            {active == "F" ? <img src={require('../assets/iconup.png')} width={10} /> : <img src={require('../assets/icondown.png')} width={10}/>}
                        </div>
                        {active == "F" && <div className="filter-tabs-data">
                            Test
                        </div>}
                    </div>
                </div>
            </div>}
            <div style={{ borderRight: '1px solid white', margin: '5px 8px', paddingRight: '7px', backgroundImage: '' }} >Filter</div>
            <div onClick={() => setShow(!show)} style={{ paddingRight: '7px', cursor: 'pointer' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                </svg>
            </div>
        </div>
    )
}


export default Filter