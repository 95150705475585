import React, { useState } from "react";
import Rating from "../../components/Rating";

const Item = (props) => {

    const { img, name, id , rating } = props.data;
    const { setDetails, index, active } = props

    return (
        <div key={index} className={`${props.listing == "L" ? "list-item" : "item"} ${id == active ? props.listing == "L" ? "list-item-active" : 'item-active' : ''}`} onClick={() => { props.click(props.data, id); }} >
            <div className={`${props.listing == "G" ? "itemsimg" : "hide-img"}`} >
                <img className="productimg" src={img} alt={name} style={{ objectFit: 'contain' }} />
            </div>
            <div className={`${props.listing == "G" ? "itemdata" : "list-item-data"}`} >
                <div className={` ${props.listing == "G" ? 'text-center text-light mx-2' : 'fw-bold text-light'} `} style={{ fontSize: "13px", lineHeight: '13px', marginLeft: props.listing == "G" ? '0px' : '15px', maxWidth: 200, height: props.listing == "G" ? 26 : 'auto' }}>
                    {name.length < 25 ? name : name.slice(0, 23) + ".."} {props.listing != "G" && <span onClick={() => setDetails(props.data)} style={{ cursor: "pointer" }}><img src={require('../../assets/Info.png')} width={13} height={13} /> </span>}</div>
                {props.listing == "G" && <div style={{ cursor: 'pointer', width: 'fit-content', margin: '0px auto', color: 'darkgray', fontSize: 10, cursor: 'pointer' }} onClick={() => setDetails(props.data)} >more info  <img src={require('../../assets/Info.png')} width={10} height={10} /></div>}
                <div className={`${props.listing == "G" ? "additioninfo" : "list-item-addtional-data"}`}>
                    <div className={`${props.listing == "G" ? " " : "mx-2"}`} style={{display:'flex' ,flexDirection:'column', justifyContent:'center' , alignItems:'center'}}>
                        {props.listing == "G" && <div className="text-light">Performance rating</div>}
                        <Rating rating={rating} size={10} />
                    </div>
                    {props.listing == "G" ?
                        <div className={`${props.listing == "G" ? " text-end " : "mx-3"}`} >
                            <div style={{ color: "rgba(51, 196, 212, 1)" }}>+2 days</div>
                            <div style={{ color: "rgba(51, 196, 212, 1)" }}> $ 600</div>
                        </div>
                        :
                        <>
                            <div className="px-3" style={{ color: "rgba(51, 196, 212, 1)", borderLeft: '1px solid gray' }}>+2 days</div>
                            <div className="px-3" style={{ color: "rgba(51, 196, 212, 1)", borderLeft: '1px solid gray' }}> $ 600</div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Item