import React, { useState } from "react";

const ListingStyle = (props) => {

    const options = ['Assending', 'Decending'];
    const { ListingStyle, setListingStyle } = props;

    return (
        <div style={{ display: 'flex', fontSize: '13px', alignItems: "center", backgroundColor: '#29254c', color: 'white', borderRadius: '7px', lineHeight: '18px', position: 'relative', boxShadow: '0px 2px 3px 2px #1d1c37' }} >
            <div onClick={() => setListingStyle("G")} className="listing-style" style={{  backgroundColor: ListingStyle == "G" ? "rgba(117, 92, 221, 0.3)" : 'transparent' }} >
                <img src={require('../assets/gridview.png')} width={12} height={12} style={{ objectFit: 'contain' }} />
            </div>
            <div onClick={() => setListingStyle("L")}className="listing-style" style={{  backgroundColor: ListingStyle == "L" ? "rgba(117, 92, 221, 0.3)" : 'transparent' }}>
                <img src={require('../assets/listview.png')} width={12} height={12} style={{ objectFit: 'contain' }} />
            </div>
        </div>
    )
}


export default ListingStyle